import React from 'react'
import Layout from '../../templates/Login'
import Form from '../../modules/Form'

const LoginPage = ({ params }) => {
    return (
        <Layout id={params.id}>
            <Form view='login'/>
        </Layout>
    )
}

export default LoginPage
